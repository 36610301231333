.itopia-dark .mat-card { border: solid 1px $card-dark-border !important;}
.itopia-light .mat-card {  border: 1px solid $card-light-border!important;}
.mat-card .mat-card-title h2 span{  font-size: 1.42rem; }
.mat-icon { font-size: 1.71rem; }

.mat-card-subtitle {
  font-size: 0.8rem !important;
  margin-top: 0px !important;
  opacity: 0.7 !important;
}
.mat-card-title{
  font-size: 1.42rem !important;
  margin-bottom: 0px !important;
 }
.mat-card .mat-card-title h2 .mat-icon{
  position: relative;
  top: 5px;
  margin-right: 0.3125rem;
  font-size: 1.71rem;
}



