@import './assets/scss/globals/itopia-theme.scss';
@import './assets/scss/globals/colors.scss';
h3, h4 {
  font-weight: 500 !important;
  margin-bottom: 2px !important;
}
.itopia-dark .accordion-container {
  background-color: $dark-background !important;
}
.search-select {
  width: 90%;
  padding-left: 5%;
  margin-top: 5%;
}
.search-input {
  width: 30% !important;
  min-width: 160px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis-r-1 {
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 10rem;
}
.ellipsis-r-2 {
  overflow: hidden;
  line-height: 2rem;
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
}
.ellipsis-r-3 {
  overflow: hidden;
  line-height: 1rem;
  max-height: 4rem;
  max-width: 213px;
  -webkit-box-orient: vertical;
  display: block !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  word-wrap: break-word;
}
.badgec {
  position: absolute !important;
  margin-top: 7px;
}
.text-success {
  color: #31b11a !important;
}
.text-danger {
  color: #b11a1a !important;
}
.text-primary {
  color: $primary-color !important;
}
.text-warning {
  color: #edca1e !important;
}
.text-success-dark {
  color: #20950b;
}
.c-text-warn {
  color: #f44336 !important;
}
.c-pointer {
  cursor: pointer;
}
.toolbar-btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
}
