@import '~@angular/material/theming';
@import './colors.scss';
@import './spacing.scss';
@import './card.scss';
@import './dialog.scss';
@import './side-menu.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat-core();

// Define the default theme (same as the example above).
$itopia-light-primary: mat-palette($mat-blue, 900);
$itopia-light-accent:  mat-palette($mat-blue-grey, A200, A100, A400);
$itopia-light-warn:    mat-palette($mat-red);
$itopia-light:   mat-light-theme((
  color: (
    primary: $itopia-light-primary,
    accent: $itopia-light-accent,
    warn: $itopia-light-warn
  )
));

$foreground-light: map-get($itopia-light, foreground);
$foreground-light-palette: map-merge($foreground-light, (foreground: $ligth-typo));
$foreground-light: map-get($foreground-light-palette, foreground);

// Include the default theme styles (color and default density)
@include angular-material-theme($itopia-light);

.itopia-light {
  @include angular-material-color($itopia-light);
  color: $foreground-light;
}

.itopia-light .mat-menu-item .mat-icon-no-color, .itopia-light .mat-menu-item-submenu-trigger::after,
.itopia-light .mat-list-base .mat-list-item {
  color: $foreground-light;
}
.itopia-light .mat-card,
.itopia-light .mat-datepicker-content,
.itopia-light .mat-dialog-container,
.itopia-light .mat-expansion-panel,
.itopia-light .mat-menu-item,
.itopia-light .mat-flat-button, .itopia-light .mat-raised-button, .itopia-light .mat-fab, .itopia-light .mat-mini-fab,
.itopia-light .mat-menu-panel {
  color: $foreground-light;
}
.itopia-light .mat-drawer,
.itopia-light .mat-drawer-container {
  color: $foreground-light;
}

.itopia-light  a {
  color: $primary-color;
}
.itopia-light .mat-table,
.itopia-light .mat-paginator,
.itopia-light .mat-button-toggle-appearance-standard {
  background: $ligth-secondary;
  color: $foreground-light !important;
}

.itopia-light .mat-button-toggle-checked {
  background-color: $ligth-header !important;
}

// Define an alternate dark theme.
$itopia-dark-primary: mat-palette($mat-blue, 500);
$itopia-dark-accent:  mat-palette($mat-blue-grey, A200, A100, A400);
$itopia-dark-warn:    mat-palette($mat-deep-orange);
$itopia-dark:   mat-dark-theme((
  color: (
    primary: $itopia-dark-primary,
    accent: $itopia-dark-accent,
    warn: $itopia-dark-warn
  )
));

$background-dark: map-get($itopia-dark, background);
$background-palette: map-merge($background-dark, (background: $dark-background));
$background-dark: map-get($background-palette, background);

.itopia-dark {
  @include angular-material-color($itopia-dark);
  background-color:  $dark-background;
  color: white;
}
.itopia-dark .mat-menu-item .mat-icon-no-color, .itopia-dark .mat-menu-item-submenu-trigger::after,
.itopia-dark .mat-list-base .mat-list-item {
  color: white;
}

.itopia-dark .mat-card,
.itopia-dark .mat-datepicker-content,
.itopia-dark .mat-dialog-container,
.itopia-dark .mat-expansion-panel,
.itopia-dark .mat-menu-item,
.itopia-dark .mat-flat-button, .itopia-dark .mat-raised-button, .itopia-dark .mat-fab, .itopia-dark .mat-mini-fab,
.itopia-dark .mat-menu-panel,
.itopia-dark .mat-select-panel,
.itopia-dark .mat-stepper-horizontal, .itopia-dark .mat-stepper-vertical {
  background:  $dark-card-background;
  color: white;
}
.itopia-light .mat-snack-bar-container {
  background-color:  $ligth-background;
  color: $foreground-light;
}
.itopia-dark .mat-drawer,
.itopia-dark .mat-drawer-container{
  background-color:  $dark-background !important;
}

.itopia-dark .mat-app-background, .itopia-dark.mat-app-background {
  background-color: $dark-background;
}

.itopia-dark .mat-snack-bar-container {
  background-color:  $dark-secondary;
  color: white;
}

.itopia-dark .mat-table,
.itopia-dark .mat-paginator,
.itopia-dark .mat-button-toggle-appearance-standard {
  background: $dark-secondary;
}

.itopia-dark .mat-button-toggle-checked {
  background-color: $dark-header !important;
}

.itopia-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(4, 34, 64, 0.2), 0px 3px 4px 0px rgba(4, 34, 64, 0.14), 0px 1px 8px 0px rgba(4, 34, 64, 0.12);
}

.itopia-dark a {
  color: $primary-color-dark;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 13px;
  overflow-y: auto;
  overflow-x:hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.cdk-global-scrollblock {
  height: auto;
}
body {
  margin: 0;
  font-family: "Roboto,Helvetica Neue,sans-serif";
}
.page-wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.text-center {
  text-align: center;
}
// tables
.table-auto {
  table-layout: auto !important;
}
table {
  width: 100%;
  table-layout: fixed;
}
th,
td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
}
th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  width: 40px !important;
}
.mat-column-select {
  overflow: initial;
}
.mat-form-field {
  width: 65%;
}
.code-container {  background-color: #e3e9ef; border: solid 1px #aeb4f1; border-radius: 4px; font-family: 'Fira Code', monospace !important; font-weight: 600;}

.mat-badge-content {
  width: 39px !important;
  height: 22px !important;
  line-height: 1.57rem;
  font-weight: 500 !important;
  right: -46px !important;
  top: 0px !important;
}
.timepicker-overlay { z-index: 1000 !important;}
.w-100 {
  width: 100%;
}
.itopia-dark .mat-tooltip
{
  background: $tooltip-dark-background;
}
.itopia-light .mat-tooltip {
  background: $tooltip-light-background;
  color: #151B1E;
}

