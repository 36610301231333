/*primary palette**/
$primary-color  :#01579B;
$action-color-light: #0277BD;
$primary-color-dark:#42a5f5;
$principal-color:#42a5f5;
$success-color  :#109618;
$error-color    :#DC3912;
$warn-color     :#FF9900;


/**light theme colors*/
$ligth-background     : #fff;
$light-card-background: #fafafa;
$ligth-typo           : #37474F;
$ligth-secondary : #fbfbfb;
$ligth-header : #0d47a11f;

/**dark theme colors*/
$dark-background     : #011223;
$dark-card-background: #131c29;
$dark-typo           : #fff;
$dark-buttons        : #2e2e3b;
$disable-buttons     : #0000001f;
$dark-secondary : #1a222dfa;
$dark-header : #42a5f51c;

/*tooltips*/
$tooltip-light-background: #c0c7cf;
$tooltip-dark-background: #1b2d4a;

/*snackbar*/
$snackbar-background: #37374b;



/**chart colors**/
$chart-primary: #3366CC;
$chart-second : #DC3912;
$chart-third  : #FF9900;
$chart-fourth : #109618;
$chart-fifth  : #990099;
$chart-sixth  : #0099C6;
$chart-seventh: #DD4477;
$chart-eighth : #66AA00;
$chart-nineth : #E67300;
$chart-tenth  : #CCCCCC;

/*gray colors*/
$gray-color0 :#797979;
$gray-color1 :#CCCCCC;
$gray-color2 :#cac6c6;
$gray-color3 :#f3f3f3;
$gray-color4 : #e7e7e7a1;

/*hovers*/
$primary-hover         : #324ad5;
$toolbar-icons         :#6fc5ff40;
$primary-disabled      : #2196f35c;
$action-dark           :#202531;
$action-light          :#cbd8e5;
$secundary-dark-hover  :#424250;
$secondary-light-hover : #eeeef9;
$warn-hover            : #f7695f;
$accent-hover          : #fdaf3d;
$disabled-hover        : #ffffff1f;
$strocket-hover        : #6e7dff3d;
$button-disabled-dark  : #ffffff4d;
$button-disabled-light : #00000042;

/*cards*/
$card-dark-border  : #2d3135;
$card-light-border : #c3d6df;

/*pannels*/
$panel-dark-background: #2a2a2a;
$panel-dark-expansion : #303031;
$panel-dark-body      : #1a1a20;

$panel-light-background: #F4F7FA;
$panel-light-expansion : #9dacff4d;
$panel-light-body      : #fbfbfb;

/*stepper**/
$stepper-dark-header  : #242425;
$stepper-light-header : #F4F7FA;
$stepper-light-content: #EAEDF0;

/**disable*/
$disable-dark-button      :#42425030;
$disable-dark-button-color:#525252;
$disable-light-back       :#f0efff;
$disable-light-color      :#8284a361;
$disable-light-primary-bg :#2196f35c;
$disable-light-primary    :white;
$disable-dark-primary-bg  :#2196f35c;
$disable-dark-primary     :#ffffff47;


/**colors clasess*/
.primary-color {
  color: $primary-color;
}

.success-color {
  color: $success-color;
}

.error-color {
  color: $error-color;
}

.warn-color {
  color: $warn-color;
}

/**radio buttons, checkboxs & toggles*/
$selection-light-control:#274f6496;
$selection-dark-control :#d1d1df96;
$select-primary-checked :#2196f39e
